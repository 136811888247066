module.exports = {
  background: '#e6ebe0',
  backgroundDark: '#f4f1bb',

  primary: '#ed6a5a',
  primaryLight: '#ed6a5a',
  primaryDark: '#ed6a5a',

  secondary: '#5ca4a9',
  secondaryLight: '#9bc1bc',
  secondaryDark: '#c60055',
};
